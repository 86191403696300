import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // 主页
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue'),
    meta: {
      titlezh: '利星行汽车',
      titleen: 'LSH Auto China',
      content: {
        keywordszh: '利星行汽车，利星行，LSH，奔驰经销商',
        keywordsen: 'mercedes dealership, LSH, LSH auto group, authorised mercedes dealers,  luxury cars, used luxury cars',
        descriptionzh: '汽车经销商利星行汽车作为奔驰中国经销商，在中国82个城市拥有146个服务网点。利星行汽车提供新车与认证二手车销售与售后服务，包括汽车技术和金融保险服务。访问利星行汽车官网了解更多详情。',
        descriptionen: 'As one of the largestes authorised mercedes dealers in China, LSH Auto group has spread to 82 cities in China. LSH Auto China provides premium new and used luxury cars sales and services.'
      }
    }
  },
  // 关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    redirect: '/aboutUs/overview',
    component: () => import('../views/aboutUs/AboutUs.vue'),
    children: [
      // 公司概览
      {
        path: 'overview',
        name: 'OverView',
        component: () => import('../views/aboutUs/ComOverview.vue'),
        meta: {
          titlezh: '集团概览|利星行汽车',
          titleen: 'Overview | LSH Auto China',
          content: {
            keywordszh: '利星行汽车集团，汽车服务',
            keywordsen: '',
            descriptionzh: '利星行汽车集团中国总部在北京，分部在上海。利星行汽车集团专注于豪华汽车销售,售后及其相关汽车服务，连续九年位列中国汽车流通协会发布的“中国汽车经销商集团百强排行榜”榜单前五位。访问利星行汽车官网了解更多详情。',
            descriptionen: ''
          }
        }
      },
      // 我们的团队
      {
        path: 'people',
        name: 'OuerPeople',
        component: () => import('../views/aboutUs/OuerPeople.vue'),
        meta: {
          titlezh: '我们的团队|利星行汽车',
          titleen: 'Our People | LSH Auto China',
          content: {
            keywordszh: '利星行汽车',
            keywordsen: '',
            descriptionzh: '利星行汽车中国团队拥有约16000员工，截至2017年底已有4000余人拥有梅赛德斯奔驰官方认证。利星行汽车集团秉承“予之学，学至精，精于行”的宗旨，开设利星行培训学院培养优秀的专业人才。',
            descriptionen: ''
          }
        }
      },
      // 我们的历史
      {
        path: 'history',
        name: 'OuerHistory',
        component: () => import('../views/aboutUs/OuerHistory.vue'),
        meta: {
          titlezh: '我们的历史|利星行汽车',
          titleen: 'History | LSH Auto China',
          content: {
            keywordszh: '授权经销商，上海奔驰经销商',
            keywordsen: '',
            descriptionzh: '利星行汽车作为梅赛德斯奔驰的官方授权经销商，于1993年在上海开设了奔驰经销商门店，正式进军中国大陆市场。利星行汽车热衷于公共慈善事业，其董事长庄国邦亲自提出了“百所希望小学”计划。2018年第30所利星行希望小学捐建落成。',
            descriptionen: ''
          }
        }
      },
      // 企业荣誉
      {
        path: 'awards',
        name: 'CompAwards',
        component: () => import('../views/aboutUs/CompAwards.vue'),
        meta: {
          titlezh: '企业荣誉|利星行汽车',
          titleen: 'Awards | LSH Auto China',
          content: {
            keywordszh: '企业荣誉,中国汽车流通协会',
            keywordsen: '',
            descriptionzh: '利星行汽车集团多年来荣获了多个行业奖项，包括中国汽车流通协会颁发的”品牌影响力经销商集团“，”公益贡献奖“等等。不仅如此，利星行汽车团队还多次拿到梅赛德斯-奔驰授予的各种技术和团队奖项。',
            descriptionen: ''
          }
        }
      }
    ]
  },
  // 业务介绍
  {
    path: '/business',
    name: 'Business',
    redirect: '/business/premium-auto-brands',
    component: () => import('../views/workerIntro/WorkerIntro.vue'),
    children: [
      // 豪华汽车品牌
      {
        path: 'premium-auto-brands',
        name: 'LuxuryCarBrand',
        component: () => import('../views/workerIntro/LuxuryCarBrand.vue'),
        meta: {
          titlezh: '豪华汽车品牌|利星行汽车',
          titleen: 'Premium Auto Brands | LSH Auto China',
          content: {
            keywordszh: '利星行奔驰，戴姆勒集团',
            keywordsen: 'mercedes benz, benz retail, mercedes benz auto dealer',
            descriptionzh: '利星行汽车集团和奔驰制造商戴姆勒集团的长期紧密合作,使利星行汽车具有得天独厚的优势为客户提供专业的服务。2017年戴姆勒集团入股利星行，更加巩固了利星行奔驰经销商的领先地位',
            descriptionen: 'LSH Auto China partners with Daimler to deliver the superior customer experience for benz retail. The decades-long partnership with Daimler also helped LSH Auto China establish expertise as a mercerdes benz auto dealer'
          }
        }
      },
      // 新车销售
      {
        path: 'new-car-sales',
        name: 'NewCarSales',
        component: () => import('../views/workerIntro/NewCarSales.vue'),
        meta: {
          titlezh: '新车销售|利星行汽车',
          titleen: 'New Cars | LSH Auto China',
          content: {
            keywordszh: '奔驰，迈巴赫，AMG，奔驰smart，购买新车',
            keywordsen: 'buy a car, mercedes, new cars, mercedes car, mercedes amg, mercedes maybach, smart car, benz showroom',
            descriptionzh: '利星行汽车的品牌合作伙伴包括奔驰，迈巴赫，AMG和奔驰smart。长期深入的品牌合作使利星行汽车深刻理解品牌的核心价值观并成为了品牌方信赖的合作伙伴。利星行汽车运用先进的技术推出数字展厅，致力于提供卓越的客户体验。',
            descriptionen: 'As a leading expert in luxury automotive retail industry, LSH Auto China has exceeded aunnal new car sales of more than 100K units since 2015. LSH Auto China keeps the strong partnerships with Mercedes-Benz, Mercedes-Maybach, Mercedes-AMG, and smart brands. The benz digital showrooms are launched to enrich the customer experice'
          }
        }
      },
      // 二手车
      {
        path: 'used-car-sales',
        name: 'UsedCarSales',
        component: () => import('../views/workerIntro/UsedCarSales.vue'),
        meta: {
          titlezh: '二手车销售|利星行汽车',
          titleen: 'Pre-Owned Cars | LSH Auto China',
          content: {
            keywordszh: '二手车出售，奔驰二手车，官方认证二手车，星睿二手车，二手车交易，奔驰认证二手车',
            keywordsen: 'used cars for sale, used mercedes benz, certified pre-owned cars, used car consignment, used car broker',
            descriptionzh: '自2014年成为梅赛德斯奔驰星睿授权经销商，至2017年利星行汽车二手车交易辆已达到35000辆。利星行出售的每一辆奔驰认证二手车都需要通过专业技师的全面检测，确保认证的星睿二手车是原厂配件，质量保证',
            descriptionen: 'Since LSH Auto China received the authorization of Mercedes-Benz StarElite dealer, it has developed certified pre-owned cars network through 33 cities. LSH also offers service for used car broker and used car consignment.'
          }
        }
      },
      // 售后服务
      {
        path: 'aftersales-services',
        name: 'AfterServices',
        component: () => import('../views/workerIntro/AfterServices.vue'),
        meta: {
          titlezh: '售后服务|利星行汽车',
          titleen: 'Aftersales | LSH Auto China',
          content: {
            keywordszh: '汽车售后服务，汽车保养，汽车维修，汽车配件，汽车救援服务',
            keywordsen: 'car service, after sales service, mercedes assessories, benz maintenance, mercedes benz extended warranty, mercedes roadside assistance',
            descriptionzh: '利星行汽车拥有专业领先的技术团队，在历届奔驰服务技能大师中国赛中成绩优异，曾两次包揽该赛事的全部团体和个人奖项。利星行提供的汽车售后服务包括汽车保养，汽车维修，汽车配件，汽车救援服务等等。',
            descriptionen: 'Besides keeping a distinguished track record on the Mercedes-Benz Techmasters China competition, LSH Auto China also introduced the Technical Engineer Program to ensure the best technical and service quality in the car service industry. The after-sales service of LSH Auto China include mercedes benz accessories, benz maintenance, benz extended warranty, mercedes roadside assistance,car wash, etc.'
          }
        }
      },
      // 金融保险服务
      {
        path: 'financing-and-insurance',
        name: 'FinancEInsurance',
        component: () => import('../views/workerIntro/FinancEInsurance.vue'),
        meta: {
          titlezh: '金融保险服务|利星行汽车',
          titleen: 'Financing &amp; Insurance | LSH Auto China',
          content: {
            keywordszh: '汽车金融，汽车保险，汽车金融公司，汽车延保，二手车保险',
            keywordsen: 'car insurance, car finance,  used car insurance, extended warranty',
            descriptionzh: '为满足消费者多种购车方式的需求，利星行汽车与多家汽车金融公司合作为消费者提供相应的解决方案。利星行汽车还提供汽车延保，车辆保险，二手车保险等多元化险种，一站式服务给消费者带来便利快捷的购车体验。',
            descriptionen: 'LSH Auto China provides convenient one-stop services for customers and offers various car finance services and car insurance, including used car insurance, extended warranty,etc.'
          }
        }
      }
    ]
  },
  // 经销网络
  {
    path: '/network',
    name: 'SellNetwork',
    component: () => import('../views/SellNetwork.vue'),
    meta: {
      titlezh: '经销网络|利星行汽车',
      titleen: 'Dealership Network | LSH Auto China',
      content: {
        keywordszh: '利星行奔驰4S店',
        keywordsen: 'mercedes benz dealership locator, nearest mercedes benz dealership',
        descriptionzh: '查看利星行奔驰4s店电话，地址，和微信二维码',
        descriptionen: 'LSH Auto China has 146 outlets across 82 cities in China. Check out our mercedes benz dealership locator to find out the nearest mercedes benz dealership'
      }
    }
  },
  // 企业责任
  {
    path: '/responsibility',
    name: 'BusResponsibility',
    component: () => import('../views/BusResponsibility.vue'),
    meta: {
      titlezh: '企业责任|利星行汽车',
      titleen: 'Corporate Social Responsibility | LSH Auto China',
      content: {
        keywordszh: '企业的社会责任',
        keywordsen: 'ccorporate social responsibility',
        descriptionzh: '利星行汽车在中国的发展的25年间一直积极履行企业的社会责任。利星行汽车捐赠的30所希望小学已经使超过1万名孩子们受益。除此之外，利星行汽车还为残障儿童筹集资金，帮助残障儿童的幸福成长。',
        descriptionen: 'LSH Auto China is dedicated to giving back to the community in China. During the past 25 years, LSH Auto China has donated 30 hope schools benefiting more than 10,000 children. Moreover, LSH Auto China launched programs to support disabled kids'
      }
    }
  },
  // 职业发展
  {
    path: '/career',
    name: 'CareerDevelop',
    component: () => import('../views/CareerDevelop.vue'),
    meta: {
      titlezh: '加入我们|利星行汽车',
      titleen: 'Join Us | LSH Auto China',
      content: {
        keywordszh: '利星行汽车集团',
        keywordsen: 'lsh auto careers, lsh auto jobs',
        descriptionzh: '利星行汽车集团重视人才的发展和培养，提供有竞争力的薪酬福利和舒适的工作环境。快来加入我们吧！',
        descriptionen: 'Find the latest LSU Auto jobs opportunities here and join the team today!'
      }
    }
  },
  // 新闻中心
  {
    path: '/news',
    name: 'NewCenters',
    component: () => import('../views/NewCenters.vue'),
    meta: {
      titlezh: '新闻|利星行汽车',
      titleen: 'News | LSH Auto China',
      content: {
        keywordszh: '利星行汽车',
        keywordsen: 'lei shing hong',
        descriptionzh: '查看利星行汽车的所有资讯和所获荣誉奖项。',
        descriptionen: 'Keep up to date all the news of LSH Auto China and check out all the awards weve won'
      }
    }
  },
  // 新闻详情
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/newsDetail/newsDetail.vue'),
    meta: {
      titlezh: '新闻详情|利星行汽车',
      titleen: 'newsdetail',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 联系我们
  {
    path: '/contact',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      titlezh: '联系我们|利星行汽车',
      titleen: 'Contact Us | LSH Auto China',
      content: {
        keywordszh: '利星行汽车',
        keywordsen: 'lsh china',
        descriptionzh: '查看利星行汽车北京总部的地址，电话和邮箱。',
        descriptionen: 'Find out the contact phone number, email address, and headquarter address of LSH Auto China. We look forward to hearing from you!'
      }
    }
  },
  // 免责声明
  {
    path: '/disclaimer',
    name: 'DisclaimerCopyright',
    component: () => import('../views/DisclaimerCopyright.vue'),
    meta: {
      titlezh: '免责声明|利星行汽车',
      titleen: 'disclaimer',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 隐私条款
  {
    path: '/privacy',
    name: 'PrivacyClause',
    component: () => import('../views/PrivacyClause.vue'),
    meta: {
      titlezh: '隐私条款|利星行汽车',
      titleen: 'privacy',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 网站地图
  {
    path: '/sitemap',
    name: 'SiteMap',
    component: () => import('../views/SiteMap.vue'),
    meta: {
      titlezh: '网站地图|利星行汽车',
      titleen: 'sitemap',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 维护页面
  {
    path: '/Maintain',
    name: 'Maintain',
    component: () => import('../views/Maintain.vue'),
    meta: {
      titlezh: '网站维护|利星行汽车',
      titleen: 'Maintain',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 隐私政策
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement/agreement.vue'),
    meta: {
      titlezh: '隐私政策|利星行汽车',
      titleen: 'agreement',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  },
  // 404页面
  {
    path: '*',
    name: 'notFound',
    component: () => import('../components/notFound'),
    meta: {
      titlezh: '404|利星行汽车',
      titleen: 'anotFound',
      content: {
        keywordszh: '',
        keywordsen: '',
        descriptionzh: '',
        descriptionen: ''
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
