import router from '../router'

router.beforeEach(async (to, from, next) => {
  const pathType = ['network', 'aboutUs', 'business', 'responsibility', 'career', 'news', 'contact', 'vr']
  const timer = setInterval(() => {
    if (window.$client_id) {
      if (pathType.indexOf(to.path.split('/')[1]) !== -1) {
        if (to.path.split('/')[1] === 'aboutUs') {
          to.meta.pageGroup = 'about us'
        } else {
          to.meta.pageGroup = to.path.split('/')[1]
        }
      } else {
        if (to.path === '/') {
          to.meta.pageGroup = 'homepage'
        }
      }
      window.gtag('event', 'page_view', {
        site: localStorage.languageSet,
        page_group: to.meta.pageGroup,
        send_to: 'G-9LRNGL9NJ8'
      })
      clearInterval(timer)
    }
  }, 400)
  // /* 路由发生变化修改页面title */
  if (to.meta['title' + localStorage.languageSet]) {
    // alert(`${localStorage.languageSet}`)
    document.title = to.meta['title' + localStorage.languageSet]
    document.getElementById('keywords').setAttribute('content', to.meta.content['keywords' + localStorage.languageSet])
    document.getElementById('descriptionkey').setAttribute('content', to.meta.content['description' + localStorage.languageSet])
  }
  if (to.matched.length === 0) {
    // 如果未匹配到路由
    from.name ? next({ name: from.name }) : next('/')
    // 如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  }
  next()
})
