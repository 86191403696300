<template>
  <div>
    <div :class="menuClassName ? 'menuOpen' : ''">
      <div class="headerWrapper show">
        <div class="header">
          <!-- logo -->
          <a href="/" class="logo logoImage imgBtn" @click="logoClick">
            <img
              src="../../assets/homePage/logo-cn.png"
              alt="LSH"
            />
            <!-- <img src="../../assets/homePage/logo-en.png" alt="LSH" /> -->
            <!-- <p v-if="pc" style="position: absolute;top: -8px;right: -35px;font-size: 20px;color: #000;">©test</p>
          <p v-else style="position: absolute;top: -16px;right: -35px;font-size: 20px;color: #000;">©test</p> -->
          </a>
          <!-- 中英文切换 -->
          <div @click="languageBtn" class="enBox menuBtn">
            <a href="javascript:;">{{ $t("language.name") }}</a>
          </div>
          <a href="javascript:void(0);" class="menuBtn" @click="showMenu">
            <span class="bar1"></span>
            <span class="bar2"></span>
            <span class="bar3"></span>
          </a>
        </div>

        <!-- 中部区域 -->
        <div class="menuList" id="divtop">
          <!-- <div class="arrow" style="left: 376.104px; width: 61.1771px;"></div> -->
          <!-- 一级菜单 -->
          <!-- logo -->
          <a href="/" class="logo logoImage imgBtns" @click="logoClick">
            <img
              src="../../assets/homePage/logo-cn.png"
              alt="LSH"
            />
            <!-- <img  src="../../assets/homePage/logo-entemp.png" alt="LSH" /> -->
            <!-- <p v-if="pc" style="position: absolute;top: -8px;right: -35px;font-size: 20px;color: #000;">©test</p>
          <p v-else style="position: absolute;top: -16px;right: -35px;font-size: 20px;color: #000;">©test</p> -->
          </a>
          <div
            v-for="item in $t('headerData')"
            :key="item.id"
            @mouseover="hoverNav(item, true)"
            @mouseout="hoverNav(item, false)"
            @click="hoverClick(item)"
            :class="item.id === initHeaderId ? 'menuItem active' : 'menuItem'"
          >
            <!-- <div v-if="item.navProperty" class="mainMenuItem">{{ item.navName }}</div> -->
            <!-- <router-link else class="mainMenuItem" :to="{path:item.href}" tag="div">{{ item.navName }}</router-link> -->

            <a
              class="mainMenuItem"
              @click="mainMenuItemClick(item)"
              :href="item.href"
              tag="div"
              >{{ item.navName
              }}<span
                v-if="item.navProperty"
                class="icon icon-arrowDown"
              ></span>
            </a>
            <!-- 二级菜单 -->
            <transition name="fade" mode="out-in" appear>
              <div
                :class="item.id === initHeaderId ? 'subMenu active' : 'subMenu'"
              >
                <div
                  class="subMenuItem"
                  v-for="itm in navPropertyData"
                  :key="itm.id"
                >
                  <!-- <a href="/zh/overview">{{ itm.propertyName }}</a> -->
                  <a :href="itm.href" @click="subMenuItemClick(itm)">{{
                    itm.propertyName
                  }}</a>
                </div>
              </div>
            </transition>
          </div>
          <div class="rightIcon">
            <!-- 微博 -->
            <a
              href="https://m.weibo.cn/u/2153910991"
              target="_blank"
              class="weiboBtn"
              @click="weiboFun()"
            >
              <img
                style="width: 30px; height: 30px"
                src="../../assets/homePage/sns/weibo.png"
                alt="weibo"
              />
            </a>
            <!-- 微信 -->
            <span href="/" class="weichatBtn" @click="weichatFun()">
              <img
                style="width: 30px; height: 30px"
                src="../../assets/homePage/sns/wechat.png"
                alt="weichat"
              />
            </span>
            <!-- 红书 -->
            <span href="/" class="redBtn" @click="redbootFun()">
              <img
                style="width: 30px; height: 30px"
                src="../../assets/homePage/sns/redboot.png"
                alt="miniredboot"
              />
            </span>
          </div>
          <!-- 中英文切换 -->
          <!-- <div @click="languageBtn" class="langBox langBoxs">
            <a href="javascript:;">{{ $t("language.name") }}</a>
          </div> -->
          
        </div>
      </div>
      <div style="" class="dimBg"></div>
    </div>
    <div class="weixinMask" v-if="isMask">
      <!-- pc -->
      <div class="weixinBtns">
        <p class="maskBtn">
          <span @click="maskFun()" style="cursor: pointer"
            ><img src="../../assets/fonts/images/common/icon_x.png"
          /></span>
        </p>
          <img
            class="qr"
            :src="this.locale == 'zh' ? p1 : p2"
            alt="weichat_qr"
          />
          <div class="qr weichat-code">
            <div class="scan-code" v-show="locale != 'zh'"><i class="phone-scan"></i>Scan QR Code on mobile devices</div>
            <div class="scan-code-desc" v-show="locale != 'zh'">Visit LSH Auto(China) WeChat Mini Program</div>
            <div class="scan-code" v-show="locale == 'zh'"><i class="phone-scan"></i>手机扫码</div>
            <div class="scan-code-desc" v-show="locale == 'zh'">访问利星行汽车中国小程序</div>
          </div>
      </div>
      <!-- mobile -->
      <div class="weixinBtn" style="background-color: rgba(0, 0, 0, 0.5)">
        <div
          style="
            width: 100vw;
            height: 100vh;
            background-color: rgb(0 0 0 / 29%);
          "
        >
          <div class="qrms">
            <img
              class="maskBtns"
              @click="maskFun()"
              src="../../assets/homePage/sns/close.png"
              alt=""
            />
          </div>
          <img
            class="qrm"
            :src="this.locale == 'zh' ? p3 : p4"
            alt="weichat_qr"
          />
          <!-- <div class="qrm weichat-code-m">
            <div class="weichat-content">
            <div class="scan-codem" v-show="locale != 'zh'"><i class="phone-scan"></i>Scan QR Code on mobile devices</div>
            <div class="scan-code-descm" v-show="locale != 'zh'">Visit LSH Auto(China) WeChat Mini Program</div>
            <div class="scan-codem" v-show="locale == 'zh'"><i class="phone-scan"></i>手机扫码</div>
            <div class="scan-code-descm" v-show="locale == 'zh'">访问利星行汽车中国小程序</div>
          </div> -->
          </div>
        </div>
      </div>
      <div class="weixinMask" v-if="isRedMask">
        <!-- pc -->
        <div class="weixinBtns">
        <div class="qrco" >
          <div class="qr weichat-code">
          </div>
          <span @click="maskRedFun()" class="redxdown"
              ><img src="../../assets/fonts/images/common/icon_x.png"
            /></span>
        </div>
        </div>
        <!-- mobile -->
        <div class="weixinBtn" style="background-color: rgba(0, 0, 0, 0.5)">
        <div
          style="
            width: 100vw;
            height: 100vh;
            background-color: rgb(0 0 0 / 29%);
          "
        >
        <div class="qrms qrmsco" >
        <span @click="maskRedFun()" class="qrmco"
            ><img  src="../../assets/homePage/sns/close.png"
          /></span>
        </div>
        </div>
        </div>
      </div>
      <!-- <div class="qr"></div> -->
      <!-- <div>

         <img class="qr" src="../../assets/homePage/sns/wechat_qr.png" alt="weichat_qr" />
         <p class="phone"><img src="../../assets/homePage/sns/phone.png" alt="phone" /><span>手机扫码</span></p>
         <p class="visit">访问利星行汽车中国官网公众号</p>
      </div> -->
    <!-- <weixin-mask v-if="isMask"></weixin-mask> -->
  </div>
</template>

<script>
import Vue from "vue";
import "../../assets/common.scss";
import p1 from "../../assets/homePage/sns-new/QR-1580x780.png";
import p2 from "../../assets/homePage/sns-new/QRenglish.png";
import p3 from "../../assets/homePage/sns-new/shade.png";
import p4 from "../../assets/homePage/sns-new/Qenglishmo.png";
export default {
  components: {},
  data() {
    return {
      navCheckedData: "",
      navIsShow: false,
      initHeaderId: "",
      menuClassName: false,
      isshow: false,
      currentItem: null,
      locale: "",
      p1: p1,
      p2: p2,
      p3: p3,
      p4: p4,
      newsInfo: {
        languageType: "",
        pageNo: 1,
        pageSize: 9,
      },
      isMask: false,
      isRedMask: false
    };
  },
  created() {
    this.locale = localStorage.languageSet;
  },
  mounted() {
    this.navCheckedData = this.$t("headerData")[0].navName;
    // this.initHeaderId = this.$t('headerData')[0].id
    this.$nextTick(function () {
      // 滚动监听事件
      window.addEventListener("scroll", this.topFun);
    });
  },
  methods: {
    topFun() {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      var top_div = document.getElementById("divtop");
      if (t > 0) {
        top_div.style.top = "0px";
      } else {
        top_div.style.top = "20px";
      }
    },
    showMenu() {
      this.menuClassName = !this.menuClassName;
    },
    languageBtn() {
      this.$i18n.locale === "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh"); // 设置中英文模式
      this.$store.dispatch("getLocali18n", this.$i18n.locale);
      localStorage.setItem("languageSet", this.$i18n.locale); // 将用户设置存储到localStorage以便用户下次打开时使用此设置
      this.newsInfo.languageType = this.$i18n.locale;
      this.$store.dispatch("getNewsList", this.newsInfo);
      this.$router.go(0);
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: this.$i18n.locale,
        send_to: "G-9LRNGL9NJ8",
      });
    },
    logoClick() {
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: "logo",
        send_to: "G-9LRNGL9NJ8",
      });
    },
    subMenuItemClick(itm) {
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: itm.propertyName,
        send_to: "G-9LRNGL9NJ8",
      });
    },
    mainMenuItemClick(item) {
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: item.navName,
        send_to: "G-9LRNGL9NJ8",
      });
    },
    hoverNav(item, boolParams) {
      if (Vue.prototype.wrapperClassName !== "desktop") return;

      this.navIsShow = !!(
        boolParams &&
        item.navProperty &&
        item.navProperty.length > 0
      );
      if (this.navIsShow) {
        this.navCheckedData = item.navName;
      }
      if (
        this.initHeaderId !== item.id &&
        item.navProperty &&
        item.navProperty.length
      ) {
        this.initHeaderId = item.id;
      } else {
        // setTimeout(() => {
        this.initHeaderId = "";
        // }, 3000);
      }
    },
    hoverClick(item) {
      if (Vue.prototype.wrapperClassName === "desktop") return;

      if (
        this.initHeaderId !== item.id &&
        item.navProperty &&
        item.navProperty.length
      ) {
        this.initHeaderId = item.id;
      } else {
        this.initHeaderId = "";
      }
      // console.log(item)
      if (item.navProperty && item.navProperty.length > 0) {
        this.navCheckedData = item.navName;
      }
    },
    weichatFun() {
      this.isMask = true;
      this.menuClassName = false;
      this.stopScroll();
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: "微信",
        send_to: "G-9LRNGL9NJ8",
      });
    },
    weiboFun() {
      this.$gtag("event", "click", {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: "global navigation",
        event_label: "微博",
        send_to: "G-9LRNGL9NJ8",
      });
    },
    maskFun() {
      this.isMask = false;
      this.canScroll();
    },
    maskRedFun () {
      this.isRedMask = false
      this.canScroll()
    },
    // 禁止滚动
    stopScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面滑动
    },
    /** *取消滑动限制***/
    canScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    redbootFun () {
      this.isRedMask = true
      this.menuClassName = false
      this.stopScroll()
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'global navigation',
        event_label: '小红书',
        send_to: 'G-9LRNGL9NJ8'
      })
    }
  },
  computed: {
    navPropertyData: {
      get: function () {
        const that = this;
        const navproperty = this.$t("headerData").filter(function (item) {
          return item.navName === that.navCheckedData;
        });

        if (navproperty.length !== 0) {
          return navproperty[0].navProperty;
        }
        return navproperty;
      },
    },
  },
};
</script>
<style scoped>
/* .qr{
  background-image: url("../../assets/homePage/sns/微信QR-弹窗.png");
  background-position:50%;
  background-repeat:no-repeat;
  background-size:cover;
  width: 100%;
  height: 100%;
} */
.qr {
  width: 821px;
  position: absolute;
  left: 0;
  right: 0;
  top: 38px;
  margin: auto;
}
.qrco {
  width: 388px;
  height: 77%;
  background: url('../../assets/homePage/sns-new/red.png') no-repeat top center;
  background-size: 100% 477px;
  left: 0;
  right: 0;
  top: 136px;
  margin: auto;
  position: absolute;
}
.weichat-code{
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-top: 310px;

}
.weichat-code-m{
  font-size: 13px;
  color: #fff;
  text-align: center;
  position: relative;
}
.weichat-content{
    position: absolute;
    bottom: 26px;
    width: 100%;
    text-align: center;
}
.phone-scan{
  background: url('../../assets/homePage/sns-new/phone.png') no-repeat center center;
    width: 33px;
    height: 42px;
    background-size: 33px, 42px;
    display: inline-block;
    position: relative;
    left: 3px;
    top:2px
}
.scan-code{
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
  }
  .scan-codem{
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
  }
  .scan-code-descm{
    margin-top: 13px;
    line-height: 13px;
  }
  .scan-code-desc{
    margin-top: 24px;
    line-height: 18px;
  }
.qrm {
  /* width: 66%;
  height: 56%; */
  width:290px;
  height: 447px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.qrmco {
  right: 0px;
  margin-top: -33px;
  position: absolute;
}
.qrms {
  /* width: 66%;
  height: 56%; */
  width:290px;
  height: 447px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.qrmsco {
  background: url('../../assets/homePage/sns-new/red.png') no-repeat center center;
  background-size: 290px 100%;
}
.qrmco img {
  width: 30px;
}
.menuBtn {
  display: none;
}
.imgBtn {
  display: none;
}
.imgBtns {
  display: block;
}
.weichatBtn {
  cursor: pointer;
  margin-right: 3.5px;
}
.weichatBtn img {
  vertical-align: middle;
}
.redBtn {
  cursor: pointer;
}
.redBtn img {
  vertical-align: middle;
}
.rightIcon {
  position: absolute;
  top: 0;
  right: 50px;
  /* width: 20%; */
  height: 0px;
  line-height: 80px;
}
.weiboBtn {
  display: inline-block;
  margin-right: 4px;
}
.weiboBtn img {
  vertical-align: middle;
}
.weixinBtns {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff82;
  position: relative;
}
.weixinBtn {
  display: none;
}
.redxdown {
  margin-top: -46px;
  right: -3px;
  cursor: pointer;
  position: absolute;
}
.redxdown img{
  width: 47px;
}
@media screen and (max-width: 1200px) {
  .menuBtn {
    display: block;
  }
  .imgBtn {
    display: block;
  }
  .imgBtns {
    display: none;
  }
  .logoImage img {
    display: block;
    width: 100%;
    height: auto;
  }
  .rightIcon {
    position: static;
    height: 30px;
    line-height: 0px;
    text-align: center;
    margin: 20px;
  }
  .langBoxs {
    display: none;
  }
  .enBox {
    height: 60px;
    right: 40px;
    line-height: 60px;
  }
  .enBox a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    color: #303030;
  }
  .mobile .menuOpen .menuList {
    top: 60px !important;
  }
}
@media screen and (max-width: 780px) {
  /* .imgBtns {
    display: none;
  }
  .imgBtn {
    display: block;

  } */
  .qrmsco {
    background-size: 102% 370px;
  }
  .qrmco {
    margin-top: 5px;
  }
  .weixinBtns {
    display: none;
  }
  .weixinBtn {
    display: block;
    overflow: auto;
  }
  .weichatBtn img {
    /* width: 30px;
  object-fit: cover; */
  }
}
</style>
