module.exports = {
  language: {
    name: '中文'
  },
  headerData: [
    {
      id: 1,
      navName: '关于我们',
      navProperty: [
        { id: 11, propertyName: '公司概览', href: '/aboutUs/overview' },
        { id: 12, propertyName: '我们的团队', href: '/aboutUs/people' },
        { id: 13, propertyName: '我们的历史', href: '/aboutUs/history' },
        { id: 14, propertyName: '企业荣誉', href: '/aboutUs/awards' }
      ]
    },
    {
      id: 2,
      navName: '业务介绍',
      navProperty: [
        { id: 21, propertyName: '豪华汽车品牌', href: '/business/premium-auto-brands' },
        { id: 22, propertyName: '新车销售', href: '/business/new-car-sales' },
        { id: 23, propertyName: '二手车', href: '/business/used-car-sales' },
        { id: 24, propertyName: '售后服务', href: '/business/aftersales-services' },
        { id: 25, propertyName: '金融保险服务', href: '/business/financing-and-insurance' }
      ]
    },
    { id: 3, navName: '经销网络', href: '/network' },
    { id: 4, navName: '企业责任', href: '/responsibility' },
    { id: 5, navName: '职业发展', href: '/career' },
    { id: 6, navName: '新闻中心', href: '/news' },
    { id: 7, navName: '联系我们', href: '/contact' }
  ],
  footerData: {
    company: ' 利星行（中国）汽车企业管理有限公司 版权所有',
    companyTwo: '',
    companyICP: '京ICP备15023982号-5',
    website: [
      { id: 1, propertyName: '免责声明', href: '/disclaimer' },
      { id: 2, propertyName: '隐私条款', href: '/privacy' },
      { id: 3, propertyName: '网站地图', href: '/sitemap' }
      // { id: 4, propertyName: '二手车线上展厅', href: 'https://ucc.lshauto.com.cn/home' }
    ],
    footerRecord: [
      {
        id: 1,
        navName: '关于我们',
        navProperty: [
          { id: 11, propertyName: '公司概览', href: '/aboutUs/overview' },
          { id: 12, propertyName: '我们的团队', href: '/aboutUs/people' },
          { id: 13, propertyName: '我们的历史', href: '/aboutUs/history' },
          { id: 14, propertyName: '企业荣誉', href: '/aboutUs/awards' },
        ]
      },
      {
        id: 2,
        navName: '业务介绍',
        navProperty: [
          { id: 21, propertyName: '豪华汽车品牌', href: '/business/premium-auto-brands' },
          { id: 22, propertyName: '新车销售', href: '/business/new-car-sales' },
          { id: 23, propertyName: '二手车', href: '/business/used-car-sales' },
          { id: 24, propertyName: '售后服务', href: '/business/aftersales-services' },
          { id: 25, propertyName: '金融保险服务', href: '/business/financing-and-insurance' }
        ]
      },
    ],
    footerTitle:[
      { id: 31, propertyName: '经销网络', href: '/network' },
      { id: 32, propertyName: '企业责任', href: '/responsibility' },
      { id: 33, propertyName: '职业发展', href: '/career' },
      { id: 34, propertyName: '新闻中心', href: '/news' },
      { id: 35, propertyName: '联系我们', href: '/contact' },
    ],
  },
  notFound:{
    title:"找不到符合条件的页面",
    text:"找不到符合条件的页面。页面可能已遭移除、重新命名或已不是原来的网址。",
  },
  // 联系我们
  contact: {
    hideText: '联系我们',
    title: '利星行汽车',
    address: '地址：',
    phone: '电话：',
    phoneInfo: '(+86) 10 8477 8000',
    time:'',
    timeInfo:'工作时间 周一至周五 9:00-18:00',
    email: '邮箱：',
    emailInfo: 'info.autocn@lsh.com',
    addressCompony: '利星行（中国）汽车企业管理有限公司',
    addressSouth1: '北京市 朝阳区 广顺南大街8号院',
    addressSouth2: '',
    addressCenter: '利星行中心3号楼G/H座3层 邮编100102',
    mapData: {
      mapTitle: '利星行中心',
      mapCompany: '利星行（中国）汽车企业管理有限公司',
      mapAddress: '北京市 朝阳区 广顺南大街8号院',
      mapZipCode: '利星行中心3号楼G/H座3层 邮编100102'
    }
  },
  // 新闻中心
  newCenter: {
    hideText: '新闻',
    releaseTime: '发布时间',
    queryMore: '查询更多',
    prev: '上一篇',
    next: '下一篇',
    goUp: '返回列表'
  },
  // 职业发展
  careerDev: {
    hideText: '加入我们',
    descriptionInfo: '利星行汽车是1.5万多名员工的选择。',
    descriptionInfotwo: '我们寻找活力四射、热爱汽车行业，愿意积极贡献社会、对企业的发展有所建树的有志之士加入利星行汽车大家庭。',
    title: '选择利星行汽车',
    subtitleLeft: '行业特点',
    descripLeft: '利星行汽车专注高端豪华汽车品牌的经销和维修服务，秉承',
    descripLeft1:'“客为先，诚致远”的服务理念，打造专业、高效、细致的服务体验。我们汇集爱车懂车的人，服务我们尊贵的客户。',
    descripLeft2:'利星行汽车专注高端豪华汽车品牌的经销和维修服务，秉承“客为先，诚致远”的服务理念，打造专业、高效、细致的服务体验。我们汇集爱车懂车的人，服务我们尊贵的客户。',
    descripLeft3: '',
    subtitleRight: '公司文化',
    descripRight: '我们推崇高效的企业文化，鼓励员工持续学习和开放思维，积极培养员工的专业技能和领导力。秉持创新理念，以成为更好的自己。',
    subbottomtitleLeft: '职业平台',
    descripbottomLeft: '利星行汽车业务分布在全国87个城市，有224个经销网点。多品牌的业务线构建了员工职业发展的广阔平台。我们有完善的人才培养和人才选拔机制助力员工成长。',
    subbottomtitleRight: '福利待遇',
    descripbottomRight: '我们为员工提供富有市场竞争力的薪酬。公司合法合规为员工缴纳各项社保和公积金，并提供其他附加福利，如福利年假、员工节日关怀等。',
    inner: '加入我们',
    readMore:'点击查看更多热招职位',
    readmoreText:'关注“利星行汽车招聘”公众号了解更多',
    mail: 'hr-career.autocn@lsh.com'
  },
  // 企业责任
  BusResponse: {
    hideText: '企业责任',
    headTitle: '惠泽',
    headTitleTwo: '桃李',
    description: '利星行汽车积极履行企业社会责任，回馈社会。',
    descripDetail: '利星行汽车公益以帮扶青少年教育为核心，其中一大公益项目是筹建希望学校。自 2007年资助的首座希望小学落成以来，利星行在中国帮助建立和捐赠了 35 所希望学校，惠及 10,000 多名青少年。很多利星行汽车经销商负责人荣幸地担任了所在地区希望学校的名誉校长。',
    headUnderline: '星耀未来',
    publicWelfare: {
      title: '新冠疫情的“暖心盒子”公益',
      introduction: '利星行汽车加入由北京青少年发展基金会发起的向抗疫医务奉献者奉献关爱行动，携手北京利星行慈善基金会向北京地区抗疫一线的医务人员表达深深的敬意与感激之情。'
    },
    honor: {
      title: '企业社会责任荣誉',
      introduction: '2017年，利星行汽车荣膺中国汽车流通协会评选的“中国汽车流通行业企业品牌最具影响力奖”和“社会责任公益奖”。'
    },
    summerCamp: {
      title: '公益夏令营',
      introduction: '2015年7月12日-17日， 2015首届利星行汽车希望学校公益夏令营在北京举行，来自利星行汽车援建的希望学校逾百名儿童欢聚北京。'
    },
    sliderItemList: [
      {
        title: '新冠疫情的“暖心盒子”公益',
        introduction: '利星行汽车加入由北京青少年发展基金会发起的向抗疫医务奉献者奉献关爱行动，携手北京利星行慈善基金会向北京地区抗疫一线的医务人员表达深深的敬意与感激之情。',
        url: 'firstSlide.jpg'
      },
      {
        title: '企业社会责任荣誉',
        introduction: '2017年，利星行汽车荣膺中国汽车流通协会评选的“行业企业品牌最具影响力奖”和“社会责任公益奖”。',
        url: 'twoSlide.jpg'
      },
      {
        title: '公益夏令营',
        introduction: '2015年7月12日-17日， 2015首届利星行汽车希望学校公益夏令营在北京举行，来自利星行汽车援建的希望学校逾百名儿童欢聚北京。',
        url: 'threeSlide.jpg'
      }
    ]
  },
  // 经销网络
  SellNetwork: {
    hideText: '经销网络',
    findDealer: '查找经销商',
    searchDealer: '查找经销商'
  },
  // 业务介绍-豪华汽车品牌
  luxuryCarBrand: {
    bannerTitle: '豪华汽车品牌',
    sectionTitleOne: '',
    sectionTitle: '专注豪华汽车品牌服务',
    centralContent: '利星行汽车专注豪华汽车品牌服务，经销网络已经遍布全国87个城市。凭借30多年的发展积累，我们提供豪华汽车品牌经销服务及贴心的客户体验，不断为满足客户需求而努力。',
    upper: {
      leftTitle: '业务分布广泛',
      leftInfo: '我们在中国重要城市拥有深厚的积淀，并在快速变化的市场中占据一席之地。',
      rightTitle: '优秀的客户体验',
      rightInfo: '提供优秀客户体验是我们的使命。'
    },
    lower: {
      leftTitle: '产品和服务创新',
      leftInfo: '我们坚持不断创新，努力为客户提供个性化服务。'
    },
    textColumn: '来自客户支持和认可，对合作伙伴的使命和承诺，推动着利星行汽车成为梅赛德斯-奔驰品牌的亲密伙伴。数十年以来，通过双方的紧密合作，让我们能够竭诚为客户提供出色的服务。'
  },

  // 业务介绍-新车销售
  newCarSales: {
    bannerTitle: '新车',
    sectionTitle: '传承',
    and: '',
    excellentTitle: '',
    centralContent: '30多年的传承与发展，利星行汽车始终走在豪华汽车经销服务领域的行业前列。自 2015年销量超过10万台以来，年销量持续攀升。',
    upper: {
      leftTitle: '覆盖奔驰全系车型',
      leftInfo: '其中包括梅赛德斯-奔驰，梅赛德斯-迈巴赫，梅赛德斯-AMG，梅赛德斯-EQ，smart等。',
      rightTitle: '广泛的销售网络',
      rightInfo: '我们的网络覆盖全国87个城市的224个经销网点（包括便捷服务中心）。'
    },
    lower: {
      leftTitle: '一站式购车服务',
      leftInfo: '为客户提供便捷贴心的一站式购车服务。',
      rightTitle: '数字化展厅体验',
      rightInfo: '完备的服务设施，热情专业的服务团队，打造数字化的展厅体验。'
    }
  },
  // 业务介绍-二手车
  usedCarSales: {
    bannerTitle: '二手车',
    sectionTitleOne: '品质',
    sectionTitle: '承诺',
    sectionTitleTwo: '',
    centralContent: '我们于2014年首次获得梅赛德斯-奔驰官方认证二手车授权经销商资格，开始梅赛德斯-奔驰官方认证二手车业务。',
    upper: {
      leftTitle: '网络覆盖',
      leftInfo: ['152家经销商', '19家利星行二手车中心', '遍布全国87个城市'],
      centerTitle: '丰富车源',
      centerInfo: ['奔驰等品牌', '任君选择'],
      rightTitle: '一站式服务',
      rightInfo: ['评估、检测、整备', '美容、维修']
    },
    lower: {
      leftTitle: '轻松购车',
      leftInfo: ['分期付款', '保险服务'],
      centerTitle: '利星行认证',
      centerInfo: ['17大类158项', '1年/2万公里质保'],
      rightTitle: '专业技师',
      rightInfo: ['专业的检测和维修团队', '提供技术保障']
    },
    online: {
      title: '利星行二手车线上展厅',
      text: '利星行二手车线上展厅提供优质丰富的车源信息，让您安心便捷的体验二手车购车服务，及参加多姿多彩的臻选活动。'

    }

  },
  // 业务介绍-售后服务
  afterServices: {
    bannerTitle: '售后服务',
    sectionTitleOne: '专业团队',
    sectionTitle: '铸就品质服务',
    centralContent: '利星行汽车配备符合奔驰标准的优秀服务设施，并拥有专业的售后服务团队，致力于为客户提供专业、个性化的服务，以满足客户的需求。在历届梅赛德斯-奔驰技能大师赛中，利星行汽车表现突出，近些年来赢得多项团体和个人奖项。我们还引入了技术工程师（Technical Engineer）项目，此举旨在确保所有维修工作达到严格的质量标准。',
    upper: {
      leftTitle: '车辆保养与维修',
      leftInfo: '我们的售后团队经过资格认证，为客户提供高品质的车辆保养和维修服务。',
      rightTitle: '事故救援与保险理赔业务',
      rightInfo: '我们提供及时的车辆救援以及一站式保险理赔和维修服务。'
    },
    lower: {
      leftTitle: '配件、精品和选装件',
      leftInfo: '我们提供多种配件、精品及选装件，满足客户的个性化需求。',
      rightTitle: '汽车后市场业务',
      rightInfo: '我们的汽车后市场业务包括延保、汽车美容服务及其他衍生产品和服务。'
    }
  },
  // 业务介绍-金融保险服务
  financEInsurance: {
    bannerTitle: '金融保险服务',
    sectionTitleOne: '个性化',
    sectionTitle: '服务',
    centralContent: '利星行汽车秉承以客户为中心的服务理念。为满足客户的个性化需求，我们与多个知名金融保险机构携手，提供多元化的金融保险产品和服务。',
    upper: {
      leftTitle: '多样化的购买渠道',
      leftInfo: '我们与多家合作伙伴紧密协作，为客户提供多样化的产品购买渠道。',
      rightTitle: '多元化的产品和服务',
      rightInfo: '客户可随心挑选符合自身需求的产品和服务。'
    }
  },
  // 关于我们-企业荣誉
  compAwards: {
    bannerTitle: '企业荣誉',
    slickListChCompet: [
      {
        id: 31,
        title0: '勇夺冠军、亚军、季军团队奖项以及三项个人奖项',
        title: '获得6个团队及7个个人奖项',
        date: '2023 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'chComptTwo.jpg'
      },
      {
        id: 32,
        title: '荣获4项团体及7项最佳个人奖项，5支团队进入决赛12强',
        date: '2021 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'chComptOne1.png'
      },
      {
        id: 33,
        title: '获得6个团队及9个个人技能及钣喷奖项',
        date: '2020 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'chComptOne.png'
      }
    ],
    slickListMasters: [
      {
        id: 11,
        tit0: '获得3个个人单项冠军，6个个人全能冠军，1个经销商团体冠军',
        title: '获得7个奖项类别中的6项冠军',
        date: '2024 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'mastersTwo.jpg'
      },
      {
        id: 12,
        title: '获得星徽卓越团队全国三甲中的两强席位，9个个人赛奖项；此外利星行销售团队还获得1项最具凝聚力奖和10项最佳风范奖',
        date: '2021 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'mastersThree.png'
      },
      {
        id: 13,
        title: '获得全国2支星徽卓越团队奖，5个个人赛奖项，此外利星行汽车销售团队还获得了1项星徽精英奖、10项职业风范奖和2项杰出创新奖',
        date: '2019 年',
        grantedBy: '梅赛德斯-奔驰授予',
        url: 'mastersOne.jpg'
      }
    ],
    slickListSure: [
      {
        id: 1,
        title: '中国汽车流通行业百强前五',
        date: '2009-2024 年',
        grantedBy: '',
        grantedByBr: '',
        url: 'upOne.png'
      },
      {
        id: 2,
        title: '品牌影响力经销商集团',
        date: '2011-2023 年',
        grantedBy: '',
        grantedByBr: '',
        url: 'upTwo.jpg'
      },
      {
        id: 3,
        title: '公益贡献',
        date: '2011-2023 年',
        grantedBy: '',
        grantedByBr: '',
        url: 'upThree.jpg'
      }
    ],
    chinaCompetition: {
      benz: '梅赛德斯-奔驰',
      chCompetition: '服务技能大师中国大赛',
      description: '我们的售后团队一直保持优异成绩。'
    },
    skillMasters: {
      benz: '梅赛德斯-奔驰',
      master: '销售技能大师赛',
      description: '我们的销售精英们载誉归来。'
    },
    industryAffirmation: {
      industry: '行业',
      sure: '肯定',
      description: '利星行汽车在稳健经营业务的同时，积极回馈社会，赢得多项行业荣誉。'
    }
  },
  // 关于我们-我们的历史
  ouerHistory: {
    bannerTitle: '我们的历史',
    start: '开始',
    textLeftUp: '1993年，利星行汽车进入中国大陆市场，成为梅赛德斯-奔驰的区域代理商和经销商。同年，在上海成立了中国大陆首家正式授权的梅赛德斯-奔驰经销商。',
    textLeftDown: '随后，分别在北京（1994年）、青岛（1996年）和厦门（1997年）等重要城市开设了经销商网点。',
    textRightUp: '截至2023年12月，我们的足迹已遍布中国87个城市，建立了224个经销网点（包括便捷服务中心）。',
    textRightDown: '利星行汽车开展众多社会公益活动，为社会增添色彩，捐建希望小学以支持青少年教育，在2007年捐资完成了第一所利星行希望学校。截至2023年，全国已有35所利星行希望学校，惠及10,000多名青少年。',
    timelineList: [
      {
        id: 1,
        activeClass: 'photo1993',
        time: 1993,
        title: '进入中国大陆市场',
        detail: '1993年，随着利星行汽车的首家经销商在上海的成立，我们的业务在中国迈出了第一步。'
      },
      {
        id: 2,
        activeClass: 'photo1994',
        time: 1994,
        title: '于北京成立利星行汽车第一个梅赛德斯-奔驰经销网点',
        detail: '抓住商机，在我们的首都北京建立梅赛德斯-奔驰经销网点。'
      },
      {
        id: 3,
        activeClass: 'photo2007',
        time: 2007,
        title: '第一所利星行希望小学竣工',
        detail: '第一所利星行汽车希望小学揭牌成立，见证我们履行企业社会责任的承诺。'
      },
      {
        id: 4,
        activeClass: 'photo2008',
        time: 2008,
        title: '培训学院成立',
        detail: '利星行汽车培训学院正式成立。'
      },
      {
        id: 5,
        activeClass: 'photo2010',
        time: 2010,
        title: '中国汽车经销商百强前五',
        detail: '迄今已经连续13年入围中国汽车流通协会评定的中国汽车经销商百强榜单并位列前五。'
      },
      {
        id: 6,
        activeClass: 'photo2013',
        time: 2013,
        title: '首家利星行汽车中心在北京竣工',
        detail: '首家利星行汽车中心在北京竣工，该中心包含一个完备的4S经销门店和钣金喷漆中心。'
      },
      {
        id: 7,
        activeClass: 'photo2015',
        time: 2015,
        title: '利星行汽车年新车销量超10万',
        detail: '新车年销量首次超过10万辆。'
      },
      {
        id: 8,
        activeClass: 'photo2018',
        time: 2018,
        title: '中国首家MAR2020展厅投入使用',
        detail: '中国首家MAR2020展厅——利星行平治投入使用，为客户提供优质的进店体验。'
      },
      {
        id: 9,
        activeClass: 'photo2019',
        time: 2019,
        title: '利星行二手车中心成立',
        detail: '利星行汽车首家二手车中心在大连正式成立。'
      },
      {
        id: 10,
        activeClass: 'photo20221',
        time: 2022,
        title: '全球首座“梅赛德斯-迈巴赫·礼境城市品牌中心”',
        detail: '全球首座“梅赛德斯-迈巴赫·礼境城市品牌中心”启幕。'
      },
      // {
      //   id: 11,
      //   activeClass: '',
      //   time: "",
      //   title: '',
      //   detail: ''
      // },
      // {
      //   id: 12,
      //   activeClass: 'photo20222',
      //   time: "",
      //   title: '利星行汽车携手焕新smart品牌',
      //   detail: '4城18家服务网点首发。'
      // }
    ],
    future: '未来'
  },
  // 关于我们-我们的团队
  ouerPeople: {
    bannerTitle: '我们的团队',
    innerTitle: '团队是利星行汽车的财富。',
    innerDetail: '1.5万多名优秀的利星行汽车员工，秉承着不断创新、爱岗敬业的精神，推动企业高速发展，提高竞争优势，让企业迈向成功。',
    ProfessionalTraining: '专业培训',
    professionalTrainingDetail: '2008 年，利星行汽车培训学院成立。秉承“予之学，学至精，精于行” 的宗旨，培养未来的公司骨干人才，储备可持续发展的人力资本。培训学院拥有完善的培训体系，提供面对面、在线和移动学习平台等多种学习渠道。所有讲师都拥有丰富的理论知识和实践经验。',
    outstandingTalents: '出色人才',
    outstandingTalentsDetail: '利星行汽车始终重视人才的选用，培养及发展。对于关键的岗位都有任职资格和专业认证要求，以及胜任力模型。拥有高素质的专业团队是利星行品牌汽车卓越优秀标准的又一体现。利星行汽车员工优秀的职业素养和精湛的专业技术在行业内享有非常高的认可度和美誉度。',
    awards: '荣誉',
    awardsDetail: '利星行汽车多次荣获梅赛德斯-奔驰、行业协会和其他专业机构颁发的各项荣誉，在国内和国际舞台上备受赞誉。特别是售后团队在梅赛德斯-奔驰服务技能大师赛中屡获殊荣。',
    MoreAwardsDetail: '查看更多荣誉',
    serviceIdea: '服务理念',
    serviceIdeaDetail: '利星行人深知与客户建立牢固互信关系的重要意义。为了建立和保持这种关系，利星行汽车坚持以客户服务为导向，为客户提供愉悦的体验。真诚为先，专业为本，及个性化服务，是利星行汽车不断为之努力的服务理念。',
    passionAndIntention: '激情用心',
    wholeheartedly: '全心全力，热情服务',
    wholeheartedlyUnderline: '在开放、透明、高效的企业文化熏陶下，利星行汽车员工高度敬业，努力提供优质服务体验。在2023年梅赛德斯-奔驰经销商员工敬业度调查中，利星行汽车经销商在中国所有梅赛德斯-奔驰经销商中名列前茅。',
    wholeheartedlyText: '很多员工已在公司奋斗多年，其中不乏自企业创办之初就与利星行携手前行的利星行人。这些经验丰富的员工，将利星行汽车的价值观继承并发展。正是这些全力以赴、尽心尽力的榜样，激励着利星行汽车所有员工锐意进取，在各自岗位上不断前行。'
  },
  // 关于我们-公司概览
  comOverview: {
    bannerTitle: '公司概览',
    textLeftUp: '利星行汽车专注豪华汽车品牌服务，经销网络遍及全国87个城市。',
    textLeftDown: '作为<a href="https://www.lsh.com" target="_Blank">利星行集团</a>',
    textRight: '',
    textRightNext: '成员，我们提供多元的产品和服务，以满足客户的多样化需求。我们的经营范围涵盖新车、二手车、汽车零部件的销售、售后服务、金融和保险及相关的汽车衍生服务。',
    textRightUrl: '',
    text: '',
    textBelong: '',
    heading: '使命和价值观',
    headingInfo: '我们的使命：建立信任、实现优异、始终如一',
    headingInfoNext: '我们的品牌价值观：',
    missionList: [
      {
        id: 1,
        heading4: '信任',
        headingDetail: '信任让我们打开大门，打破障碍，并建立起长期且富有意义的客户关系。',
        url: 'Pone.jpg',
        activeClass: 'icon icon-people'
      },
      {
        id: 2,
        heading4: '诚信',
        headingDetail: '诚信的内涵包括诚实、责任和义务。这既是对客户的尊重，也是对我们自己的尊重。诚信永远指引着我们的言行，唯有如此，我们方能保持优异的品质、赢得客户的信任。',
        url: 'Ptwo.jpg',
        activeClass: 'icon icon-hand'
      },
      {
        id: 3,
        heading4: '客户相连',
        headingDetail: '与客户相连相通，明白客户所思所想，是我们成就长期客户关系的关键。要真正做到与客户相连，我们必须保持沟通，了解实时动态，与时俱进，持续学习，着眼更广阔的未来。',
        url: 'Pthree.jpg',
        activeClass: 'icon icon-help'
      },
      {
        id: 4,
        heading4: '优异与创新',
        headingDetail: '优异来自我们对工作方方面面的专注和付出。我们持续学习，开放思维，秉持创新理念并积极投入创新实践，以成为更好的自己、成就更大的事业。',
        url: 'Pfour.jpg',
        activeClass: 'icon icon-list-up'
      },
      {
        id: 5,
        heading4: '合作与社会责任',
        headingDetail: '唯有团队合作，我们方能走得更远，让每个人获得更大的成功。我们将一如既往地建设我们扎根成长的社区，持续探索与各类机构或团体合作的机会，携手落实我们共同的企业社会责任。',
        url: 'Pfive.jpg',
        activeClass: 'iconfont icon-2'
      }
    ],
    missionListMobile: [
      {
        heading4: '信任',
        headingDetail: '信任让我们打开大门，打破障碍，并建立起长期且富有意义的客户关系。',
        url: 'Mone.jpg',
        activeClass: 'icon icon-people'
      },
      {
        heading4: '诚信',
        headingDetail: '诚信的内涵包括诚实、责任和义务。这既是对客户的尊重，也是对我们自己的尊重。诚信永远指引着我们的言行，唯有如此，我们方能保持优异的品质、赢得客户的信任。',
        url: 'Mtwo.jpg',
        activeClass: 'icon icon-hand'
      },
      {
        heading4: '客户相连',
        headingDetail: '与客户相连相通，明白客户所思所想，是我们成就长期客户关系的关键。要真正做到与客户相连，我们必须保持沟通，了解实时动态，与时俱进，持续学习，着眼更广阔的未来。',
        url: 'Mthree.jpg',
        activeClass: 'icon icon-help'
      },
      {
        heading4: '优异与创新',
        headingDetail: '优异来自我们对工作方方面面的专注和付出。我们持续学习，开放思维，秉持创新理念并积极投入创新实践，以成为更好的自己、成就更大的事业。',
        url: 'Mfour.jpg',
        activeClass: 'icon icon-list-up'
      },
      {
        heading4: '合作与社会责任',
        headingDetail: '唯有团队合作，我们方能走得更远，让每个人获得更大的成功。我们将一如既往地建设我们扎根成长的社区，持续探索与各类机构或团体合作的机会，携手落实我们共同的企业社会责任。',
        url: 'Mfive.jpg',
        activeClass: 'iconfont icon-2'
      }
    ],
    slickList: [
      { id: 1, url: 'PMendOne.jpg' },
      { id: 2, url: 'PMendTwo.jpg' },
      { id: 3, url: 'PMendThree.jpg' },
      { id: 4, url: 'PMendFour.jpg' }
    ],
    yes: '对',
    customer: '客户',
    notToMove: '坚定不移的',
    promise: '承诺',
    description: '我们竭诚为客户提供舒适、贴心的服务和体验。我们的1.5万多名员工坚持不懈地为客户提供个性化解决方案，这正是我们服务文化的体现，也是我们践行对客户承诺的见证。'
  },
  //cookile
  cookile: {
    name:'利星行汽车与Cookie : 为了能够给您提供更好的浏览体验，本网站会使用Cookie，单击‘同意’或继续浏览此网站，表示您同意我们使用cookie。',
    learnMore:"了解更多",
    agree:"同意",

  },
  // 首页
  homePage: {
    premium: '',
    name: '利星行汽车',
    namet: '',
    namedown: '滚动下拉',
    title: '豪华汽车经销',
    description: '利星行汽车专注豪华汽车品牌服务，经销网络已经遍布全国87个城市，我们致力于提供多元的产品和服务，以满足客户的多样化需求。',
    leftContent: '利星行汽车进入中国市场三十多年来，始终致力于提供创新性的产品和服务，为客户带来舒适、贴心的体验。利星行汽车连续多年跻身中国汽车流通协会发布的经销商集团百强榜单前五。',
    leftContentTwo: '',
    Create: '开创',
    limousine: '豪华汽车零售业的未来',
    count: '21.8',
    noCount: '万',
    textCar: '余辆新车',
    countAnimation: '188',
    textCity: '经销网点数目',
    cityCenter: '(包括便捷服务中心)',
    countNext: '80+',
    textNext: '个城市',
    countEmployee: '1.6',
    employeeCount: '万',
    employeeText: '余名员工',
    closingDate: '截至2020年12月31日止',
    upAnimation: '品牌合作伙伴',
    upAnimationTwo: '',
    upAnimationThree: '',
    centerUpAnimation: '利星行汽车与梅赛德斯-奔驰、梅赛德斯-AMG、梅赛德斯-迈巴赫、梅赛德斯-EQ和smart品牌建立了长期稳定的合作伙伴关系。',
    lookMore: '查看更多',
    readMore: '了解更多',
    queryMore: '查询更多',
    luxuryCarBrand: '豪华汽车品牌',
    newCar: '新车',
    usedCar: '二手车',
    afterSaleService: '售后服务',
    financialServices: '金融保险服务',
    news: '新闻',
    viewAll: '查看全部',
    ruralContentOne: '促进乡村振兴，助力民族团结 ——利星行汽车赴青海玉树开展“百年恰少年 京玉一家亲”公益活动',
    ruralContentTwo: '“长亭外，古道边，芳草碧连天……”',
    ruralContentThree: '10月21日，在青海玉树市新寨寄宿制学校的教室里，一声声稚嫩而不失深情的童声齐唱《送别》。这里正在进行由北京青少年发展基金会、利星行汽车共同主办，共青团玉树州委、共青团玉树市委协办的“百年恰少年、京玉一家亲——我在北京有个家”公益活动。',
    helpHenan: '驰援河南 利星行汽车捐助河南2家医院协助实施重建工作 ',
    helpHenanContent: '7月20日以来，一场突如其来的特大暴雨袭击河南，包括郑州在内的多地爆发了严重的洪流汛情，150个县市区共有1366.43万人受灾，更有超8万台机动车泡水。此外，从灾区需求来看，河南水灾中',
    newsTitle: '一纪百强，星耀未来 利星行汽车荣获2021年中国汽车经销商百强季军',
    newsDescription: '2021年5月28日，中国汽车流通协会在温州召开“2021中国汽车经销商大会”，凯达研究院在大会上发布了“2021中国汽车经销商集团百强排行榜”。作为行业传统，百强排行榜已经发布了12',
    ourFootprints: '我们的足迹',
    ourFootprintsCon: '利星行汽车网络发展广泛稳定，业务遍布全国87个城市，共计224个经销网点(包括便捷服务中心)。'
  },
  // 免责声明
  disclaimerCopyright: {
    bannerTitle: '免责声明和版权声明',
    disclaimer: '免责声明和版权声明',
    disclaimerNotice: '本网站上提供的信息是利星行（中国）汽车企业管理有限公司（“利星行”）目前能够获得或已知的信息。尽管利星行竭力保证信息的准确性，但是利星行:',
    disclaimerList: [
      {
        id: 1,
        detail: '保留随时不做任何通知对本网站进行修改的权利;'
      },
      {
        id: 2,
        detail: '不就信息或本网站的完整度、准确性或时效性做任何保证，亦不就此承担任何责任;'
      },
      {
        id: '3',
        detail: '不对本网站超链接的任何第三方网站内容（“第三方网站”）承担任何责任;'
      },
      {
        id: 4,
        detail: '不对与遵守任何法律、法规、法院命令或主管机关的指示有关的任何目的承担责任;'
      },
      {
        id: '5',
        detail: '不对基于合同、侵权或其他法学理论因使用或无法使用该等信息、本网站或任何第三方而引起的任何错误、遗漏或损害（包括但不限于业务或利润损失相关的损害）承担任何责任。'
      }
    ],
    oneDetail: '链接第三方网站只是为了提供便利，并不表示利星行支持、推荐或认可该第三方网站或其提供的产品或服务。',
    twoDetail: '除非法律另有规定或本网站明确表明，否则本网站用户不得将本网站上的任何信息视为利星行提供的建议或推荐。该等信息按照原内容提供，未做任何修改。',
    threeDetail: '在任何情况下，均不得为任何目的将对该等信息或本网站的使用解释为授予使用该等信息或本网站所体现或包含的任何知识产权的许可或权利。未经利星行的书面许可，任何人均不得创建或建立指向本网站的超链接。',
    fourDetail: '有关用户查看和使用该等信息或本网站的一切事宜均受中华人民共和国法律的管辖。'
  },
  // 隐私条款
  PrivacyClause: {
    bannerTitle: '隐私条款',
    privacyStatement: '隐私声明',
    subContent: [
      {
        id: 1,
        subTitle: '重要提示',
        detail: '本隐私声明普遍适用于我们的整个网站及其任何网页。请在提交或披露任何信息之前（包括个人资料），仔细阅读本隐私声明。一旦访问本网站或使用本网站的任何功能，即表示您同意下文各项条款条件。'
      },
      {
        id: 2,
        subTitle: '信息收集',
        detail: '我们收集并保留您通过我们网站或任何其它方式（譬如电子邮件）提交的任何信息（包括但不限于个人资料）。您可以选择不提供某些信息，但是您将无法使用本网站的某些或者全部功能。'
      },
      {
        id: 3,
        subTitle: '使用和目的',
        detail: '我们可能将您提交的信息用于以下目的：'
      }
    ],
    objectiveList: [
      {
        id: 21,
        detail: '回应您的请求、处理您的服务申请、为您定制未来服务、改善我们的服务或者与您沟通联系；'
      },
      {
        id: 22,
        detail: '向我们的任何集团公司、服务提供商、商业合作伙伴、代理或顾问传输、分享或核实信息；'
      },
      {
        id: 23,
        detail: '进行统计分析或提高整体的准确性或完整度；'
      },
      {
        id: 24,
        detail: '与遵守任何法律、法规、法院命令或主管机关的指示有关的任何目的；'
      },
      {
        id: 25,
        detail: '与我们或我们的任何集团公司、服务提供商、商业合作伙伴、代理或顾问的义务或交易有关的任何其他目的。'
      }
    ],
    subContentBottom: [
      {
        id: 31,
        subTitle: '安全措施',
        detail: '您通过我们网站提交的信息将受超文本传输安全协议（或者，简称“HTTPS”）加密通讯方式的保护，并且我们网站经过先进的SSL证书提供商认证。'
      },
      {
        id: 32,
        subTitle: '信息保留',
        detail: '在相关询问、申请或服务结束（以较晚者为准）后，我们会在合理时间内保留您向我们提交的个人资料。'
      },
      {
        id: 33,
        subTitle: '查阅权限',
        detail: '您有权请求查阅和更正我们保存的有关您的个人资料。如果您希望查阅或更正我方保存的个人资料，请向您询问、申请或订阅（视情况而定）之服务所在网页上公布的电邮地址/联系地址发送您的书面请求。'
      },
      {
        id: 43,
        subTitle: '责任限制',
        detail: '我们不对所收集信息的准确性、完整度或可靠度做任何保证，亦不对因信息不准确、不完整或遗漏造成的任何损失或损害承担任何责任（无论该等责任是基于侵权或合同还是其他责任理论提起）。另外，由于电邮沟通不可能完全安全，因此，如果您通过电邮与我们沟通，从而遭受任何损害，我们对此不承担任何责任。'
      }
    ]
  },
  // 网站地图
  sitMap: {
    bannerTitle: '网站地图',
    siteMapDate: [
      {
        id: 1,
        navName: '关于我们',
        navProperty: [
          { id: 11, propertyName: '公司概览', href: '/aboutUs/overview' },
          { id: 12, propertyName: '我们的团队', href: '/aboutUs/people' },
          { id: 13, propertyName: '我们的历史', href: '/aboutUs/history' },
          { id: 14, propertyName: '企业荣誉', href: '/aboutUs/awards' }
        ]
      },
      {
        id: 2,
        navName: '业务介绍',
        navProperty: [
          { id: 21, propertyName: '豪华汽车品牌', href: '/business/premium-auto-brands' },
          { id: 22, propertyName: '新车销售', href: '/business/new-car-sales' },
          { id: 23, propertyName: '二手车', href: '/business/used-car-sales' },
          { id: 24, propertyName: '售后服务', href: '/business/aftersales-services' },
          { id: 25, propertyName: '金融保险服务', href: '/business/financing-and-insurance' }
        ]
      },
      { id: 3, navName: '经销网络', href: '/network' },
      { id: 4, navName: '企业责任', href: '/responsibility' },
      { id: 5, navName: '职业发展', href: '/career' },
      { id: 6, navName: '新闻中心', href: '/news' },
      { id: 7, navName: '联系我们', href: '/contact' }
    ]
  }
}
