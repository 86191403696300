<template>
  <div id="app">
    <div :class="wrapperClassName">
      <HeaderIndex v-if="headerfooter"/>
      <router-view />
      <FooterIndex v-if="headerfooter"/>
    </div>
  </div>
</template>
<script>
import HeaderIndex from './components/header/HeaderIndex.vue'
import FooterIndex from './components/footer/FooterIndex'
import Vue from 'vue'
// import $ from 'jquery'
export default {
  data () {
    return {
      wrapperClassName: 'desktop',
      headerfooter: true
    }
  },
  components: { HeaderIndex, FooterIndex },
  methods: {
    getScreenClassName () {
      const screenW = window.innerWidth
      if (screenW > 1200) {
        this.wrapperClassName = 'desktop'
      } else if (screenW < 780) {
        this.wrapperClassName = 'mobile'
      } else {
        this.wrapperClassName = 'tablet'
      }

      Vue.prototype.wrapperClassName = this.wrapperClassName
    }
  },
  mounted () {
    // console.log(window.location.href)
    if (window.location.href.indexOf('Maintain') !== -1) {
      this.headerfooter = false
    }
    // window.resetLayout()

    // window.inView('.section_count').on('enter', function (e) {
    //   // count number
    //   if (!$('.countAnimation').hasClass('counted')) {
    //     $('.countAnimation').each(function () {
    //       const $this = $(this)
    //       const isInt = $this.text() % 1 === 0
    //       jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
    //         duration: 1500,
    //         easing: 'swing',
    //         step: function () {
    //           if (isInt) {
    //             $this.text(Math.ceil(this.Counter))
    //           } else {
    //             $this.text(this.Counter.toFixed(1))
    //           }
    //           $this.addClass('counted')
    //         }
    //       })
    //     })
    //   }
    // })
    // console.log($(window)[0], '/*------')
    $('.topFooter .contact .icon-wechat').on({
      mouseenter: function () {
        $('.topFooter .contact .wechat').addClass('active')
      },
      mouseleave: function () {
        $('.topFooter .contact .wechat').removeClass('active')
      }
    })
    window.onresize = () => {
      this.getScreenClassName()
    }
    this.getScreenClassName()
  }
}
</script>
