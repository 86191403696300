import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const COOKIE_FLAG = 'cookieflag'
export default new Vuex.Store({
  state: {
    // locali18n: localStorage.getItem('newCenter.hideText')
    locali18n: localStorage.getItem('languageSet'),
    get cookieflag () {
      return !(sessionStorage.getItem('lshcookieflag') || '')
    },
    set cookieflag (lshcookieflag) {
      sessionStorage.setItem('lshcookieflag', lshcookieflag || '')
    }
  },
  getters: {
    getLocali18n (state) {
      return state.locali18n
    }
  },
  mutations: {
    LOCALLAN (state, value) {
      state.locali18n = value
    },
    [COOKIE_FLAG](state, params) {
      state.cookieflag = params
    }
  },
  actions: {
    getLocali18n ({ commit }, value) {
      commit('LOCALLAN', value)
    },
    vuexCookieFlagFun({ commit }, params) {
      commit(COOKIE_FLAG, params)
    }
  },
  modules: {
  }
})
