<template>
  <div class="footer">
    <div v-if="cookieflag" class="cookieflag">
      <span>{{$t('cookile.name')}}<span
        @click="$router.push({ name: 'agreement' })" class="learnMore"
        >{{$t('cookile.learnMore')}}</span
      ></span> 
      <div :style="locale=='zh' ? '' : 'margin-left:10px'" @click="vuexCookieFlagFun('false')">{{$t('cookile.agree')}}</div>
      <img src="../../assets/fonts/images/common/icon_x.png" @click="vuexCookieFlagFun('false')" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  components: {},
  props: {},
  data () {
    return {
      // cookieflag: true,
      dataflag: true,
      locale:"",
    }
  },
  computed: {
    ...mapState(['cookieflag'])
  },
  watch: {},
  created () {
    this.locale = localStorage.languageSet
  },
  mounted () {
  },
  methods: {
    ...mapActions(['vuexCookieFlagFun'])
    
  }
}
</script>
<style lang="scss" scoped>
.footer {
  background: #002855;
  .cookieflag {
    background: #fff;
    position: relative;
    width: 100%;
    height: 50px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    font-size: 12px;
    text-align: left;
    flex: 1;
    .learnMore {
      color: #0563C1;
      text-decoration: underline;
      cursor: pointer;
    }
    > div {
      display: inline-block;
      line-height: 2;
      width: 60px;
      font-size: 14px;
      text-align: center;
      background: #0563C1;
      color: #fff;
      border-radius: 20px;
      cursor: pointer;
      margin-left: 30px;
    }
    img {
      // position: absolute;
      margin-left: 15px;
      // margin-top: -20px;
      width: 22px;
      cursor: pointer;
    }
  }
}
</style>
