import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/vender.scss'
import './assets/common.scss'

// 字体库
// import './assets/LSH-website-theme-1.0.0/images/favicon.ico'
// import './assets/LSH-website-theme-1.0.0/css/vender.css'
// import './assets/LSH-website-theme-1.0.0/css/icons.css'
import './assets/LSH-website-theme-1.0.0/css/iconfont.css'
import '../public/fonts/css/hyqihei.css'
// swiper
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
// 中英文
import VueI18n from 'vue-i18n'
// 百度地图
import BaiduMap from 'vue-baidu-map'
// 引入文字和icon
// import './assets/fonts/css/icons.css'
// import './assets/fonts/css/iconfont.css'
// 动画库
import 'animate.css'
import './utils/routerBeforeach'

// import './assets/jquery.inview.js'
import env from './utils/env'
import $ from 'jquery'
Vue.prototype.$ = $

Vue.use(ElementUI)
Vue.use(VueI18n)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '8BB7F0E5C9C77BD6B9B655DB928B74B6E2D838FD'
})

if (!localStorage.getItem('languageSet')) {
  localStorage.setItem('languageSet', 'zh')
}

const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet'), // 从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    zh: require('./language/zh'),
    en: require('./language/zh')
  }
})

Vue.config.productionTip = false
Vue.prototype.$gtag = function (event = 'event', type = 'click', params) {
  const category = {
    'global navigation': 'global navigation',
    footer: 'footer',
    'top banner': 'top banner',
    'main body': 'main body',
    'pic and video': 'pic and video',
    news: 'news',
    map: 'map',
    dealer: 'dealer'
  }
  const data = {
    site: localStorage.languageSet,
    send_to: 'G-9LRNGL9NJ8'
  }
  params.page_group && (data.page_group = params.page_group)
  params.event_category && (data.event_category = category[params.event_category] ? category[params.event_category] : params.event_category)
  params.event_label && (data.event_label = params.event_label)
  params.address && (data.address = params.address)
  params.dealer && (data.dealer = params.dealer)
  window.gtag(event, type, data)
}
Vue.prototype.env = env.getEnv()
Vue.prototype.mobile = false
Vue.prototype.pc = false
if (env.getEnv() === 'mobile') {
  // alert('window.env---' + window.env)
  Vue.prototype.mobile = true
  window.env = 'mobile'
} else if (env.getEnv() === 'pc') {
  Vue.prototype.pc = true
  window.env = 'pc'
}

// 全局导航钩子
router.beforeEach((to, from, next) => {
  // 进入页面滚动条返回顶部
  window.scrollTo(0, 0)
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
