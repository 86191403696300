export default {
  getEnv () {
    var ua = navigator.userAgent

    // alert('ua:::' + ua)

    var isApp = /(?::estore-app)/.test(ua)
    // alert("111111estore-app:::"+isApp)
    if (isApp) { // 原生APP
      return 'app'
    }

    // alert('wxApp')
    // return 'wxApp'

    var ualower = ua.toLowerCase()
    if (/micromessenger/.test(ualower)) {
      // alert(/micromessenger/.test(ualower) + '123')
      if (window.__wxjs_environment === 'miniprogram') { // 微信小程序
        // alert(window.__wxjs_environment + 'miniprogram')
        return 'wxApp'
      }
    }

    if (/samsungmembers/.test(ualower) || ualower.indexOf('samsungmembers') !== -1) {
      return 'mobile'
    }

    var isFireFox = /(?:Firefox)/.test(ua)
    var isAndroid = /(?:Android)/.test(ua)
    var isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
    var isWindowsPhone = /(?:Windows Phone)/.test(ua)

    var isPhone = /(?:iPhone)/.test(ua) && !isTablet

    var isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone

    var isMobile = isPhone || isAndroid || isSymbian
    if (isMobile) { // 手机
      return 'mobile'
    }

    // PC和平板
    return 'pc'
  },
  isPad () {
    // alert('document.body.clientWidth ' + document.body.clientWidth)
    if (this.getEnv() === 'app' || this.getEnv() === 'wxApp' || this.getEnv() === 'mobile') {
      if (document.body.clientWidth > 925) {
        return true
      } else {
        return false
      }
    }
  }
}
