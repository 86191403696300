<template>
  <footer id="footer" role="contentinfo">
    <div class="footerWrapper">
      <div class="footer">
        <div class="topFooter clearFix">
          <div class="colRow clearFix">
            <div v-for="item in $t('footerData.footerRecord')" :key="item.id" class="col-dk-1-4 col-tb-1-4 fLeft footerItem active">
              <div class="colInner">
                <p class="mainCat heading5">{{ item.navName }}</p>
                <div class="subFooter">
                  <a class="catItem" @click="catItemClick(itm.propertyName)" v-for="itm in item.navProperty" :key="itm.id" :href="itm.href">{{ itm.propertyName }}</a>
                </div>
              </div>
            </div>
            <div :style="screenWid>780 ? '':'clear:both'">
               <div id="screenWidorLa"
              class="col-dk-1-4 col-tb-1-4 footerItem active" style="width:26%"
              :class="screenWid>780 ? 'fLeft ':''">
                  <p class="mainCat heading5" :class="screenWid>780 ? 'heading6':'heading5'" v-for="item in $t('footerData.footerTitle')" :key="item.id"> 
                   <a style="font-size:18px" class="catItems" @click="catItemClick(item.propertyName)" :key="item.id" :href="item.href">{{ item.propertyName }}</a></p>
            </div>
            </div>
          </div>
        </div>
        <div class="footerEnd">
          <div style="text-align:right;padding:0 0 15px 0" class="sns">
            <!-- 微博 -->
            <a href="https://m.weibo.cn/u/2153910991" target="_blank" class="weiboBtn" @click="weiboFun()">
              <img style="width:30px;height:30px" src="../../assets/homePage/sns/weibo.png" alt="weibo">
            </a>
            <!-- 微信 -->
            <span href="/" class="weichatBtn" @click="weichatFun()">
              <img style="width:30px;height:30px" src="../../assets/homePage/sns/wechat.png" alt="weichat">
            </span>
            <!-- 红书 -->
            <span href="/" class="redBtn" @click="redbootFun()">
              <img style="width:30px;height:30px" src="../../assets/homePage/sns/redboot.png" alt="miniredboot">
            </span>
          </div>
          <div style="width:100%;height:1px;background-color:#909090;"></div>
          <p>
            <span class="copyright">©{{rightY()}}{{ $t( 'footerData.company') }} </span>
            <span class="icp"><a href="http://beian.miit.gov.cn" target="_blank" @click="catItemClick($t('footerData.companyICP'))">{{ $t( 'footerData.companyICP') }}</a></span>

            <!-- ©2023 -->
            <!-- <span v-for="item in $t('footerData.website')" :key="item.id" @click="showFooterItm(item.id)"
            :class="footerItem === item.id ? 'col-dk-1-4 col-tb-1-4 fLeft footerItem active': 'col-dk-1-4 col-tb-1-4 fLeft footerItem'">
            {{ item.propertyName }}eee
            </span> -->
            <span class="websiteFooter">
                <span v-for="item in $t('footerData.website')" :key="item.id">
                  <a :href="item.href" class="catItem" @click="catItemClick(item.propertyName)">{{ item.propertyName }}</a>
                  <span style="text-align:right" v-if="item.id!=$t('footerData.website').length" :key="`${item.id}_1`">|</span>
                </span>
            </span>
          </p>
        </div>
         <div v-if="isMask" class="weixinMask">
           <!-- pc -->
      <div class="weixinBtns">
        <p class="maskBtn"><span  @click="maskFun()" style="cursor:pointer">
        <img src="../../assets/fonts/images/common/icon_x.png"/></span></p>
        <img class="qr"  :src="this.locale=='zh' ? p1 : p2" alt="weichat_qr" />
      </div>
      <!-- mobile -->
      <div class="weixinBtn" style="background-color: rgba(0, 0, 0, 0.5)" >
        <div style="width:100vw;height:100vh;background-color: rgb(0 0 0 / 29%);">
          <div class="qrms">
          <img class="maskBtns" @click="maskFun()" src="../../assets/homePage/sns/close.png" alt="">
        </div>
        <img
          class="qrm"
          :src="this.locale=='zh' ? p3 : p4"
          alt="weichat_qr"
        />
        </div>
      </div>
        </div>
        <div class="weixinMask" v-if="isRedMask">
        <!-- pc -->
        <div class="weixinBtns">
        <div class="qrco" >
          <div class="qr weichat-code">
          </div>
          <span @click="maskRedFun()" class="redxdown"
              ><img src="../../assets/fonts/images/common/icon_x.png"
            /></span>
        </div>
        </div>
        <!-- mobile -->
        <div class="weixinBtn" style="background-color: rgba(0, 0, 0, 0.5)">
        <div
          style="
            width: 100vw;
            height: 100vh;
            background-color: rgb(0 0 0 / 29%);
          "
        >
        <div class="qrms qrmsco" >
        <span @click="maskRedFun()" class="qrmco"
            ><img  src="../../assets/homePage/sns/close.png"
          /></span>
        </div>
        </div>
        </div>
      </div>
        <div @click="goTop" class="footerGoTop"><span>{{ top }}</span></div>
      </div>
    </div>
    <CookieIndex class="imgBtns"/>
  </footer>
</template>

<script>
import '../../assets/common.scss'
import CookieIndex from '../../views/agreement/CookieIndex.vue'
import p1 from '../../assets/homePage/sns/QR-1580x780.png'
import p2 from '../../assets/homePage/sns/QRenglish.png'
import p3 from '../../assets/homePage/sns/shade.png'
import p4 from '../../assets/homePage/sns/Qenglishmo.png'
export default {
  components: { CookieIndex },
  data () {
    return {
      top: 'TOP',
      // footerFlag: false,
      footerItem: 0,
      isMask: false,
      locale:"",
      p1:p1,
      p2:p2,
      p3:p3,
      p4:p4,
      screenWid:0,
      footerdata:[],
      rightY: ()=>{return new Date().getFullYear()},
      isRedMask: false
      // pcTitle:false,
      // mbTitle:false,
    }
  },
   created () {
    this.locale = localStorage.languageSet
    this.screenWid = document.documentElement.clientWidth
  },
  mounted () {
    // this.languageBtn()
    // this.$i18n.locale === 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh' // 设置中英文模式
    if (this.$t('language.name') === 'EN') {
      this.$i18n.locale = 'zh'
    } else {
      this.$i18n.locale = 'en'
    }
    if(this.screenWid>780 && this.locale=="en"){  
      document.getElementById("screenWidorLa").setAttribute("style","padding-left:100px;width:50%")
    }
    
  },
  methods: {
    showFooterItm (footerId) {
      this.footerItem = footerId
      // this.footerFlag = !this.footerFlag
    },
    goTop () {
      const timer = setInterval(function () {
        const osTop = document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 10)
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'footer',
        event_label: 'TOP',
        send_to: 'G-9LRNGL9NJ8'
      })
    },
    catItemClick (itm) {
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'footer',
        event_label: itm,
        send_to: 'G-9LRNGL9NJ8'
      })
    },
    weichatFun () {
      this.isMask = true
      this.stopScroll()
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'global navigation',
        event_label: '微信',
        send_to: 'G-9LRNGL9NJ8'
      })
    },
    weiboFun () {
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'global navigation',
        event_label: '微博',
        send_to: 'G-9LRNGL9NJ8'
      })
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false)// 禁止页面滑动
    },
    redbootFun () {
      this.isRedMask = true
      this.stopScroll()
      this.$gtag('event', 'click', {
        site: localStorage.languageSet,
        page_group: this.$route.meta.pageGroup,
        event_category: 'global navigation',
        event_label: '小红书',
        send_to: 'G-9LRNGL9NJ8'
      })
    },
    maskFun () {
      this.isMask = false
      this.canScroll()
    },
    maskRedFun () {
      this.isRedMask = false
      this.canScroll()
    },
    // 禁止滚动
    stopScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false)// 禁止页面滑动
    },
    /** *取消滑动限制***/
    canScroll () {
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = ''// 出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>

<style scoped>
.desktop .col-dk-1-4{
  width: 17%;
}
.topFooter{
 margin: 0 0px 0 15px
}
.heading6{
  margin:0 0 42px;
}
.qr{
  width: 821px;
  position: absolute;
  left: 0;
  right: 0;
  top: 38px;
  margin: auto;
}
.qrm {
  /* width: 66%;
  height: 56%; */
  width:290px;
  height: 447px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.qrms {
  width:290px;
  height: 447px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.qrmsco {
  background: url('../../assets/homePage/sns-new/red.png') no-repeat center center;
  background-size: 290px 100%;
}
.qrmco img {
  width: 30px;
}
.qrmco {
  right: 0px;
  margin-top: -33px;
  position: absolute;
}
.weixinBtns{
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff82;
  position: fixed;
  right: 0;bottom: 0;
}
.weixinBtn{
  display: none;
}
.imgBtn{
    display: none;
}
.imgBtns{
    display: block;
}
.mobile .topFooter .footerItem .catItem:not(:last-child):after{
  margin: 0 5px;
}
.catItem{
  white-space: normal !important;
  word-break: break-word;
}
.websiteFooter .catItem:hover {
  color: rgba(255, 255, 255, 0.6);
}
.catItems{
color: #fff;
text-decoration: none;
}
.catItems:hover{
  color: rgba(255, 255, 255, 0.6);
}
.footerGoTop {
  z-index: 552;
}
.footerWrapper {
  /* margin-top: -7px; */
  bottom: 0;
  width: 100%;
  left: 0;
  /* z-index: 99999; */
  /* top: -7px; */
}
.weichatBtn{
  padding:5px 4px;
  cursor:pointer;
}
.weichatBtn img{
  vertical-align: middle;
}
.redBtn{
  padding:5px 4px;
  cursor:pointer;
}
.redBtn img{
  vertical-align: middle;
  border-radius: 7.8px;
}
.qrco {
  width: 388px;
  height: 77%;
  background: url('../../assets/homePage/sns-new/red.png') no-repeat top center;
  background-size: 100% 477px;
  left: 0;
  right: 0;
  top: 136px;
  margin: auto;
  position: absolute;
}
.redxdown {
  margin-top: -46px;
  cursor: pointer;
  right: -3px;
  position: absolute;
}
.redxdown img{
  width: 47px;
}
.weiboBtn{
  padding:5px 4px 5px 0;
}
.weiboBtn img{
  vertical-align: middle;
}
.weixinMask{
  position:fixed;
  top:0;
  left:0;
}
 .icp a:hover{
  color: rgba(255, 255, 255, 0.6);
 }
.footerWrapper .footer{
  padding: 42px 10px 1px !important;
}
.topFooter .mainCat{
    font-size:18px !important;
    line-height: 18px;
  }
@media screen and (max-width: 780px) {
  .imgBtns{
    display: none;
  }
  .imgBtn{
    display: block;
  }
  .qrmsco {
    background-size: 102% 370px;
  }
  .qrmco {
    margin-top: 5px;
  }
  .weixinBtns{
  display: none;
 }
  .weixinBtn{
  display: block;
  overflow: auto;
 }
 .mobile .footerWrapper .footer{
  display: block;
 }
 .footerEnd div{
  text-align: left !important;
 }
 .mobile .footerEnd p .copyright{
   display: inline-block;
   width: 100%;
   text-align: center;
 }
 .icp{
   display: inline-block;
   width: 100%;
   text-align: center;
 }
 .websiteFooter{
  display: inline-block;
   width: 100%;
   text-align: center;
 }
 .mobile .footerEnd:after{
  display: none;
 }
.footerWrapper .footer{
  padding: 42px 20px 10px !important;
}
.topFooter .colRow {
  margin-left: 0px;
  margin-right: 0px;
}
}
@media screen and (min-width: 780px) {
  
}
</style>
